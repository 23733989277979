import { Redirect, Route } from 'wouter';
import React, { useEffect, useState, Fragment } from 'react';
import MainPage from 'components/Main';
import { useLocation } from 'wouter';
import {
  clearAuthentication,
  storeRefreshToken,
  getSkolonSessionStatus,
  getInitialAuthTimestamp,
  getLastRefreshTimestamp,
  storeLastRefreshTimestamp,
} from 'utils/AuthenticationHelpers';
import axios from 'axios';
import { AuthData, SkolonAuthStatus } from 'models/enums/AuthData';

export interface ProtectedRouteProps {
  isAuthenticated: boolean;
  authenticationPath: string;
  setIsAuthenticated: (data: boolean) => void;
}

const MAX_AUTH_DURATION = 8 * 60 * 60;
const REFRESH_INTERVAL = 45 * 60;

export default function ProtectedRoute({
  isAuthenticated,
  authenticationPath,
  setIsAuthenticated,
}: ProtectedRouteProps) {
  const [location] = useLocation();
  const [refreshRequired, setRefreshRequired] = useState<boolean>(false);
  const [isRefreshing, setIsRefreshing] = useState<boolean>(false);
  let authenticatedPath: string = location;

  const refreshUserToken = async (): Promise<any> => {
    setIsRefreshing(true);
    console.log('Access token before refresh: ' + window.localStorage.getItem(AuthData.TOKEN));
    console.log('Refresh token before refresh: ' + window.localStorage.getItem(AuthData.REFRESH_TOKEN));
    const refreshToken = window.localStorage.getItem(AuthData.REFRESH_TOKEN);
    const { data } = await axios.get<any>(
      process.env.REACT_APP_BACKEND_URL! + '/api/refreshToken?refreshToken=' + refreshToken
    );
    console.log('Access token after refresh: ' + data.accessToken);
    console.log('Refresh token after refresh: ' + data.refreshToken);
    return new Promise((resolve) => {
      if (!data.error) {
        console.log('Storing refreshed token');
        storeRefreshToken(data.accessToken, data.refreshToken);
      }
      setRefreshRequired(false);
      setIsRefreshing(false);
      resolve(null);
    });
  };

  const verifyAndRefreshTokenIfNeeded = (): any => {
    const currentTimestamp = Math.floor(Date.now() / 1000);
    const initialAuthTimestamp = getInitialAuthTimestamp();
    const lastRefreshTimestamp = getLastRefreshTimestamp();

    if (currentTimestamp - initialAuthTimestamp >= MAX_AUTH_DURATION) {
      debugSpace();
      console.log('Clearing authentication, max auth duration exceeded');
      console.log('Current timestamp: ' + currentTimestamp + ' Initial auth timestamp: ' + initialAuthTimestamp);
      debugSpace();
      clearAuthentication();
      setIsAuthenticated(false);
      return <Redirect to={authenticationPath} />;
    }

    if (currentTimestamp - lastRefreshTimestamp >= REFRESH_INTERVAL) {
      debugSpace();
      console.log('Refresh required, interval exceeded');
      debugSpace();
      setRefreshRequired(true);
      return true;
    }
  };

  const debugSpace = () => {
    console.log(' ');
    console.log(' ');
    console.log(' ');
  };

  useEffect(() => {
    if (!isRefreshing) {
      const needsRefresh = verifyAndRefreshTokenIfNeeded();
      if (needsRefresh) {
        console.log('Verified, needs refresh');
        refreshUserToken().then(() => {
          storeLastRefreshTimestamp();
          debugSpace();
          console.log(
            'Accesstoken and refresh token after refresh: ' +
              window.localStorage.getItem(AuthData.TOKEN) +
              ' ' +
              window.localStorage.getItem(AuthData.REFRESH_TOKEN)
          );
          debugSpace();
          verifyUserSession();
        });
      } else {
        console.log('Verified, no refresh needed');
        verifyUserSession();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshRequired, isRefreshing, authenticatedPath]);

  const verifyUserSession = (): boolean => {
    getSkolonSessionStatus().then((status: SkolonAuthStatus) => {
      if (status === SkolonAuthStatus.NOT_AUTHENTICATED) {
        debugSpace();
        console.log('Clearing authentication, status incorrect');
        debugSpace();
        clearAuthentication();
        setIsAuthenticated(false);
        return <Redirect to={authenticationPath} />;
      }
    });

    return false;
  };

  if (isAuthenticated) {
    return (
      <Fragment>
        <Route path={authenticatedPath} component={MainPage} />
      </Fragment>
    );
  } else {
    return <Redirect to={authenticationPath} />;
  }
}
