/* eslint-disable no-unused-vars */
export enum AuthData {
  TOKEN = 'TOKEN',
  REFRESH_TOKEN = 'REFRESH_TOKEN',
  INIT_EXPIRY = 'INIT_EXPIRY',
  AUTHED_TIMESTAMP = 'AUTHED_TIMESTAMP',
  FIRST_NAME = 'FIRST_NAME',
  LAST_NAME = 'LAST_NAME',
  EMAIL = 'EMAIL',
  USER_ID = 'USER_ID',
  REDIRECTED_FOR_AUTH = 'REDIRECTED_FOR_AUTH',
  LANGUAGE = 'LANGUAGE',
  USER_TYPE = 'USER_TYPE',
  LAST_REFRESH_TIMESTAMP = 'LAST_REFRESH_TIMESTAMP',
}

export enum UserTypes {
  STUDENT = 'STUDENT',
  TEACHER = 'TEACHER',
}

/* eslint-disable no-unused-vars */
export enum UnixTimeConverter {
  MIN_10 = 600,

  //Only used for debug purposes
  MIN_59 = 3540,
}

/* eslint-disable no-unused-vars */
export enum SkolonAuthStatus {
  AUTHENTICATED = 'STANDARD',
  NOT_AUTHENTICATED = 'NOT_AUTHENTICATED',
}

export enum PageStatus {
  IsRefreshed = 'IsRefreshed',
}
