import { AuthData, SkolonAuthStatus, PageStatus } from 'models/enums/AuthData';
import axios from 'axios';
import { definableValue } from 'utils/definableValue';

export const storeInitialAuthTimestamp = () => {
  window.localStorage.setItem(AuthData.AUTHED_TIMESTAMP, Math.floor(Date.now() / 1000).toString());
};

export const getInitialAuthTimestamp = (): number => {
  const timestamp = window.localStorage.getItem(AuthData.AUTHED_TIMESTAMP);
  return timestamp ? parseInt(timestamp) : 0;
};

export const storeLastRefreshTimestamp = () => {
  window.localStorage.setItem(AuthData.LAST_REFRESH_TIMESTAMP, Math.floor(Date.now() / 1000).toString());
};

export const getLastRefreshTimestamp = (): number => {
  const timestamp = window.localStorage.getItem(AuthData.LAST_REFRESH_TIMESTAMP);
  return timestamp ? parseInt(timestamp) : 0;
};

export const storeUserAuthentication = (
  token: string,
  refreshToken: string,
  tokenExpiration: Number,
  firstName: string,
  lastName: string,
  email: string,
  userId: string,
  language: string,
  userType: string
) => {
  window.localStorage.setItem(AuthData.TOKEN, token);
  window.localStorage.setItem(AuthData.REFRESH_TOKEN, refreshToken);
  window.localStorage.setItem(AuthData.INIT_EXPIRY, tokenExpiration.toString());
  window.localStorage.setItem(AuthData.AUTHED_TIMESTAMP, Math.floor(Date.now() / 1000).toString());
  window.localStorage.setItem(AuthData.FIRST_NAME, firstName);
  window.localStorage.setItem(AuthData.LAST_NAME, lastName);
  window.localStorage.setItem(AuthData.EMAIL, email);
  window.localStorage.setItem(AuthData.USER_ID, userId);
  window.localStorage.setItem(AuthData.LANGUAGE, language);
  window.localStorage.setItem(AuthData.USER_TYPE, userType);
};

export const isUserAlreadyAuthenticated = (): boolean => {
  const token = window.localStorage.getItem(AuthData.TOKEN);
  if (definableValue(token)) {
    return true;
  } else {
    return false;
  }
};

export const storeRefreshToken = (token: string, refreshToken: string) => {
  if (definableValue(token) && definableValue(refreshToken)) {
    window.localStorage.setItem(AuthData.TOKEN, token);
    window.localStorage.setItem(AuthData.REFRESH_TOKEN, refreshToken);
  }
};

export const clearAuthentication = () => {
  //eslint-disable-next-line no-debugger
  window.localStorage.removeItem(AuthData.TOKEN);
  window.localStorage.removeItem(AuthData.REFRESH_TOKEN);
  window.localStorage.removeItem(AuthData.INIT_EXPIRY);
  window.localStorage.removeItem(AuthData.FIRST_NAME);
  window.localStorage.removeItem(AuthData.LAST_NAME);
  window.localStorage.removeItem(AuthData.EMAIL);
  window.localStorage.removeItem(AuthData.USER_ID);
  window.sessionStorage.removeItem(AuthData.REDIRECTED_FOR_AUTH);
  window.localStorage.removeItem(AuthData.AUTHED_TIMESTAMP);
  window.localStorage.removeItem(AuthData.LANGUAGE);
  window.localStorage.removeItem(AuthData.USER_TYPE);
  clearRefreshedMainpage();
};

export const getAuthenticationStringAttachment = (): string | undefined => {
  const token = window.localStorage.getItem(AuthData.TOKEN);
  const userId = window.localStorage.getItem(AuthData.USER_ID);
  if (definableValue(token) && definableValue(userId)) {
    return token + ';' + userId;
  }
  return undefined;
};

export const setHasRedirectedForAuthentication = (redirectStatus: number) => {
  window.sessionStorage.setItem(AuthData.REDIRECTED_FOR_AUTH, redirectStatus.toString());
};

export const getHasRedirectedForAuthentication = () => {
  const redirectionState = window.sessionStorage.getItem(AuthData.REDIRECTED_FOR_AUTH);
  if (definableValue(redirectionState)) {
    const redirectionStateNr = parseInt(redirectionState);
    if (redirectionStateNr === 1) {
      return true;
    } else if (redirectionStateNr === 0) {
      return false;
    }
  }
  return false;
};

export const getSkolonSessionStatus = async (): Promise<SkolonAuthStatus> => {
  try {
    const token = window.localStorage.getItem(AuthData.TOKEN);
    console.log('Accesstoken at session check: ' + token);
    if (!token) {
      return SkolonAuthStatus.NOT_AUTHENTICATED;
    }
    const { data } = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/checkSession?token=${token}`);
    console.log('Session check data: ', data);
    console.log('Token used at session check: ' + token);
    return data;
  } catch (error) {
    console.error('Session check error: ' + error);
    return SkolonAuthStatus.NOT_AUTHENTICATED;
  }
};

export const refreshedMainPage = () => {
  const isRefreshed = window.sessionStorage.getItem(PageStatus.IsRefreshed);
  if (definableValue(isRefreshed)) {
    const isRefreshedNr = parseInt(isRefreshed);
    if (isRefreshedNr === 0) {
      window.location.reload();
      window.sessionStorage.setItem(PageStatus.IsRefreshed, '1');
      return;
    }
  } else if (!definableValue(isRefreshed)) {
    window.location.reload();
    window.sessionStorage.setItem(PageStatus.IsRefreshed, '1');
  }
};

export const clearRefreshedMainpage = () => {
  window.sessionStorage.removeItem(PageStatus.IsRefreshed);
};
